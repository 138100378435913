import React from 'react';
import { ArrowRight, Check, Mail, Phone } from 'lucide-react';

const LandingPage = () => {
  return (
    <div className="min-h-screen bg-gray-100">
      {/* Header */}
      <header className="bg-blue-600 text-white p-4 md:p-6">
        <div className="container mx-auto flex justify-between items-center">
          <h1 className="text-2xl md:text-3xl font-bold">VisaExpert</h1>
          <nav>
            <ul className="flex space-x-4">
              <li><a href="#servicios" className="hover:underline">Servicios</a></li>
              <li><a href="#contacto" className="hover:underline">Contacto</a></li>
            </ul>
          </nav>
        </div>
      </header>

      {/* Hero Section */}
      <section className="bg-blue-500 text-white py-12 md:py-24">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-4xl md:text-5xl font-bold mb-4">Tu Visa, Nuestro Compromiso</h2>
          <p className="text-xl mb-8">Asesoramiento experto para hacer realidad tu viaje</p>
          <button className="bg-white text-blue-600 px-6 py-3 rounded-full font-bold hover:bg-blue-100 transition duration-300">
            Consulta Gratis
          </button>
        </div>
      </section>

      {/* Servicios */}
      <section id="servicios" className="py-12 md:py-24">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Nuestros Servicios</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {['Visas de Turismo', 'Visas de Trabajo', 'Visas de Estudio'].map((servicio, index) => (
              <div key={index} className="bg-white p-6 rounded-lg shadow-md">
                <h3 className="text-xl font-semibold mb-4">{servicio}</h3>
                <ul className="space-y-2">
                  {[...Array(3)].map((_, i) => (
                    <li key={i} className="flex items-center">
                      <Check className="text-green-500 mr-2" />
                      <span>Beneficio {i + 1}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* CTA */}
      <section className="bg-blue-600 text-white py-12">
        <div className="container mx-auto text-center px-4">
          <h2 className="text-3xl md:text-4xl font-bold mb-4">¿Listo para comenzar tu viaje?</h2>
          <p className="text-xl mb-8">Contáctanos hoy y da el primer paso hacia tu nueva aventura</p>
          <button className="bg-white text-blue-600 px-6 py-3 rounded-full font-bold hover:bg-blue-100 transition duration-300 flex items-center mx-auto">
            Empezar Ahora <ArrowRight className="ml-2" />
          </button>
        </div>
      </section>

      {/* Contacto */}
      <section id="contacto" className="py-12 md:py-24 bg-gray-100">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl md:text-4xl font-bold text-center mb-12">Contáctanos</h2>
          <div className="flex flex-col md:flex-row justify-center items-center space-y-8 md:space-y-0 md:space-x-12">
            <div className="flex items-center">
              <Mail className="text-blue-600 mr-4" size={24} />
              <span>info@visaexpert.com</span>
            </div>
            <div className="flex items-center">
              <Phone className="text-blue-600 mr-4" size={24} />
              <span>+1 234 567 8900</span>
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-gray-800 text-white py-6">
        <div className="container mx-auto text-center">
          <p>&copy; 2024 VisaExpert. Todos los derechos reservados.</p>
        </div>
      </footer>
    </div>
  );
};

export default LandingPage;